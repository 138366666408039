/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Profiler Connextion components
import MDBox from "components/MDBox";

// Profiler Connextion examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import {
  useSubCountCoachQuery,
  useSubCountSessionQuery,
  useSubCountUserQuery,
  useCountUserByConditionQuery,
} from "services/api";

function Analytics(): JSX.Element {
  const { data: coachCountData } = useSubCountCoachQuery();
  const { data: countBlockedCoach } = useCountUserByConditionQuery({
    variables: {
      where: {
        role: {
          _eq: "COACH",
        },
        deleted_at: {
          _is_null: false,
        },
      },
    },
  });
  const { data: userCountData } = useSubCountUserQuery();
  const { data: countBlockedUsers } = useCountUserByConditionQuery({
    variables: {
      where: {
        role: {
          _eq: "USER",
        },
        deleted_at: {
          _is_null: false,
        },
      },
    },
  });
  const { data: sessionCountData } = useSubCountSessionQuery();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5} minHeight={100} display="flex">
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Nombre total d'utilisateurs"
                  count={userCountData?.users_aggregate?.aggregate?.count || 0}
                  percentage={{
                    amount: countBlockedUsers?.users_aggregate?.aggregate?.count,
                    label: "Total suppressed",
                    color: "error",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5} minHeight={100} display="flex">
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Total des entraîneurs"
                  count={coachCountData?.users_aggregate?.aggregate?.count || 0}
                  percentage={{
                    amount: countBlockedCoach?.users_aggregate?.aggregate?.count,
                    label: "Total suppressed",
                    color: "error",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5} minHeight={100} display="flex">
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Nombre total de séances"
                  count={sessionCountData?.reservations_aggregate?.aggregate?.count || 0}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
