export enum TypeMessage {
  "TEXT",
  "IMAGE",
  "VIDEO",
  "AUDIO",
  "HTML",
  "PDF",
  "FILE",
  "CONTACT",
  "SOCIAL_MEDIA_LINK",
}

export const SocialMedia = {
  YOUTUBE: "Youtube",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  INSTAGRAM: "Instagram",
  LINKEDIN: "LinkedIn",
  PINTEREST: "Pinterest",
  TIKTOK: "TikTok",
  DAILYMOTION: "Dailymotion",
};

export const getIframeMessage = (message: string) => {
  if (!message) return null;
  if (!message.includes("http")) return null;

  const linkPatterns = {
    youtube: /https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(&[\w=&]*)?/g,
    // facebook: /https?:\/\/(www\.)?facebook\.com\/[\w.-]+/g,
    // twitter: /https?:\/\/(www\.)?twitter\.com\/[\w.-]+/g,
    // instagram: /https?:\/\/(www\.)?instagram\.com\/[\w.-]+/g,
    // linkedin: /https?:\/\/(www\.)?linkedin\.com\/[\w.-]+/g,
    // pinterest: /https?:\/\/(www\.)?pinterest\.com\/[\w.-]+/g,
    // tiktok: /https?:\/\/(www\.)?tiktok\.com\/[\w.-]+/g,
    dailymotion: /https?:\/\/(www\.)?dailymotion\.com\/[\w.-]+/g,
  };

  const iframes: any = [];
  const links = message.match(/https?:\/\/[^\s]+/g) || [];
  //   const log = message.split(/https?:\/\/[^\s]+/g);
  // console.log({log, links: [
  //     "Ok ",
  //     "<a href=' https://www.youtube.com/watch?v=EjajIcXkOuA&list=RDEjajIcXkOuA&start_radio=1 https://www.dailymotion.com/video/x8zkjoa'>  https://www.youtube.com/watch?v=EjajIcXkOuA&list=RDEjajIcXkOuA&start_radio=1 https://www.dailymotion.com/video/x8zkjoa</a>",
  //     ",  ",
  //     "<a href=' https://www.youtube.com/watch?v=EjajIcXkOuA&list=RDEjajIcXkOuA&start_radio=1 https://www.dailymotion.com/video/x8zkjoa'>  https://www.youtube.com/watch?v=EjajIcXkOuA&list=RDEjajIcXkOuA&start_radio=1 https://www.dailymotion.com/video/x8zkjoa</a>",
  //     " hello"
  // ]});

  links.forEach((link) => {
    if (linkPatterns.youtube.test(link)) {
      <>
        <iframe
          width="230px"
          height="120px"
          src="https://www.youtube.com/embed/h7cOOfpdEfk"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </>;
      const videoId = link.match(/v=([\w-]+)/)[1];
      const src = `https://www.youtube.com/embed/${videoId}`;
      iframes.push(
        <iframe
          width="230px"
          height="120px"
          src={src}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      );
    } else if (linkPatterns.dailymotion.test(link)) {
      const videoId = link.split("/").pop();
      const src = `https://www.dailymotion.com/embed/video/${videoId}`;
      iframes.push(
        <iframe width="230px" height="120" title="Dailymotion video player" src={src}></iframe>
      );
    }
  });

  return iframes.length ? iframes : null;
};
