/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useCallback } from "react";

// react-table components
import { useTable, usePagination, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import * as XLSX from "xlsx";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Profiler Connextion examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  GetUsersQueryVariables,
  useActiveUserByIdMutation,
  useGetUsersLazyQuery,
  useGetUsersToExportLazyQuery,
  useInActiveUserByIdMutation,
  useBlockUserMutation,
  useSoftDeleteUserMutation,
} from "services/api";
import MDBadge from "components/MDBadge";
import dayjs from "dayjs";
import { IconButton, Switch, TextField, Tooltip } from "@mui/material";
import SystemUpdateAltTwoToneIcon from "@mui/icons-material/SystemUpdateAltTwoTone";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);

  const [activeUser] = useActiveUserByIdMutation();
  const [inActiveUser] = useInActiveUserByIdMutation();
  const [blockUser] = useBlockUserMutation();
  const [softDeleteUser] = useSoftDeleteUserMutation();

  const baseQuery: GetUsersQueryVariables = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
      where: {
        role: {
          _neq: "COACH",
        },
      },
    }),
    []
  );

  const [query, { data: userResult }] = useGetUsersLazyQuery({
    variables: {
      ...baseQuery,
    },
    fetchPolicy: "no-cache",
  });

  const [queryUsersToExport] = useGetUsersToExportLazyQuery({
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    if (!userResult?.users_aggregate) return 0;

    return userResult.users_aggregate.aggregate.count;
  }, [userResult?.users_aggregate]);

  const columns = useMemo(
    () => [
      { Header: "Nom complet", accessor: "full_name" }, // Full name
      { Header: "Date de naissance", accessor: "date_of_birth" }, // Birthday
      { Header: "Lieu", accessor: "country" }, // Location
      { Header: "E-mail", accessor: "email" }, // Email
      { Header: "Rôle", accessor: "role" }, // Role
      {
        Header: "Fermé à clé",
        accessor: "is_blocked",
        Cell: (e: any) => {
          const { original } = e.cell.row;
          return (
            <Tooltip title="L'utilisateur ne peut pas se connecter lorsqu'il est bloqué">
              <Switch
                checked={original.is_blocked}
                onChange={async () => {
                  if (original.is_blocked) {
                    const isConfirmed = window.confirm("Etes-vous sûr de vouloir débloquer ?");
                    if (isConfirmed) {
                      await blockUser({
                        variables: {
                          user_id: original?.id,
                          action: "UNBLOCK",
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  } else {
                    const isConfirmed = window.confirm("Etes-vous sûr de vouloir bloquer ?");
                    if (isConfirmed) {
                      await blockUser({
                        variables: {
                          user_id: original?.id,
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  }
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        Header: "Newsletter",
        accessor: "is_agree_policy",
        Cell: (e: any) => (
          <MDBadge
            variant="contained"
            size="xs"
            badgeContent={e.value ? "Accepter" : "Pas d'accord"} // Verified / Not Verified
            container
            color={e.value ? "success" : "error"}
            fontWeight="medium"
          />
        ),
      }, // is agree policy
      {
        Header: "Vérifier",
        accessor: "is_active",
        Cell: (e: any) => {
          const { original } = e.cell.row;
          return (
            <Tooltip title="L'utilisateur vérifie déjà l'e-mail par le code OTP">
              <Switch
                checked={original.is_active}
                onChange={async () => {
                  if (original.is_active) {
                    const isConfirmed = window.confirm(
                      "Êtes-vous sûr de vouloir annuler la vérification de ce compte ?"
                    );
                    if (isConfirmed) {
                      await inActiveUser({
                        variables: {
                          user_id: original?.id,
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  } else {
                    const isConfirmed = window.confirm(
                      "Êtes-vous sûr de vouloir vérifier ce compte ?"
                    );
                    if (isConfirmed) {
                      await activeUser({
                        variables: {
                          user_id: original?.id,
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  }
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        Header: "Créé le",
        accessor: "created_at",
        Cell: (e: any) => <span>{dayjs(e.value)?.format("lll")}</span>,
      },
      {
        Header: "Supprimé",
        accessor: "deleted_at",
        Cell: (e: any) => (
          <MDBadge
            variant="contained"
            size="xs"
            badgeContent={!e.value ? "Pas encore supprimé" : "Supprimé"} // deleted / un deleted
            container
            color={!e.value ? "success" : "error"}
            fontWeight="medium"
          />
        ),
      },
      {
        Header: "Actions",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const user_id = original.id;
          return (
            <>
              {!original.deleted_at ? (
                <IconButton
                  onClick={async () => {
                    const isConfirmed = window.confirm("Etes-vous sûr que vous voulez supprimer?");
                    if (isConfirmed) {
                      await softDeleteUser({
                        variables: {
                          user_id,
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              ) : (
                <Tooltip title="L'utilisateur actuel est supprimé. Cliquez sur Si vous souhaitez restaurer">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir restaurer ?");
                      if (isConfirmed) {
                        await softDeleteUser({
                          variables: {
                            user_id,
                            action: "RECOVER",
                          },
                        });
                        await fetchData({
                          limit: pageSize,
                          offset: pageIndex * pageSize,
                        });
                      }
                    }}
                  >
                    <RestoreFromTrashOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const data = useMemo<any>(() => userResult?.users || [], [userResult]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;
    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
        order_by: { created_at: "desc" },
      },
      fetchPolicy: "no-cache",
    });
  }, []);

  useEffect(() => {
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  }, [pageIndex]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  const handleExportUsers = async () => {
    const res = await queryUsersToExport({
      variables: {
        order_by: { created_at: "desc" },
        where: {
          role: {
            _eq: "USER",
          },
        },
      },
    });
    const usersData = (res?.data?.users || []).map((user, index) => ({
      "#": index + 1,
      Id: user.id,
      Name: user?.full_name || "",
      Email: user?.email,
      Phone: user?.mobile || "",
      Role: user?.role || "",
      Verified: user?.is_active ? "Yes" : "No",
      Newsletter: user?.is_agree_policy ? "Yes" : "No",
      Blocked: user?.is_blocked ? "Yes" : "No",
      Deleted: user?.deleted_at ? "Yes" : "No",
      Deleted_at: user?.deleted_at ? dayjs(user?.deleted_at).format("YYYY-MMM-DD HH:mm:ss") : "",
      Created_at: dayjs(user?.created_at).format("YYYY-MMM-DD HH:mm:ss"),
    }));
    // Create a new workbook with a sheet
    const ws = XLSX.utils.json_to_sheet(usersData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    //  XLSX.writeFile(wb, 'users.xlsx'); // remove this line and lines code covert workbook to CSV format if you want to export without downloading file

    // Start convert the workbook to CSV format
    const csvData = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob and download the CSV file
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "users.csv";
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    // End convert the workbook to CSV format
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} minWidth={600}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries.map((e) => e.toString())}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
                fetchData({
                  limit: parseInt(newValue, 10),
                  offset: 0,
                });
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Entrées par page
            </MDTypography>
            <div>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                onChange={(e) => {
                  const text = e.target.value;
                  if (text.length > 0)
                    query({
                      variables: {
                        where: {
                          _or: [
                            {
                              email: { _like: `%${text}%` },
                            },
                            {
                              full_name: { _like: `%${text}%` },
                            },
                          ],
                        },
                        limit: 50,
                        offset: 0,
                        order_by: { created_at: "desc" },
                      },
                      fetchPolicy: "no-cache",
                    });
                }}
              />
            </div>
            <Tooltip
              title="Exportez les utilisateurs au format CSV. Le système exportera tous les utilisateurs. "
              arrow
            >
              <IconButton
                sx={{
                  "&:hover": {},
                }}
                color="inherit"
                size="medium"
                onClick={handleExportUsers}
              >
                <SystemUpdateAltTwoToneIcon fontSize="medium" />
                <span style={{ margin: "2px", padding: "2px", fontSize: 13 }}>
                  Exporter les utilisateurs
                </span>
              </IconButton>
            </Tooltip>
          </MDBox>
        )}
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Page {pageIndex + 1} sur {pageCount}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
