/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import form from "./from";

const {
  formField: { fullName, skills, email, password, birthday, gender, hourlyRate },
} = form;

const initialValues: any = {
  [fullName.name]: "",
  [email.name]: "",
  [password.name]: "",
  [skills.name]: [],
  [birthday.name]: "",
  [gender.name]: "",
  [hourlyRate.name]: "",
};

export default initialValues;
