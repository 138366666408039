import React from "react";
import { Box, Typography, Avatar, styled } from "@mui/material";
import bgImage from "assets/images/bg-contact.png";
import noPhoto from "assets/images/no-photo.png";

const CardContainer = styled(Box)({
  marginBottom: "16px",
  backgroundColor: "#fff9e6",
  borderRadius: "12px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  overflow: "hidden",
  position: "relative",
});

const Header = styled(Box)({
  backgroundImage: `url(${bgImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "12px 12px 0 0",
  padding: "22px 14px 38px 14px",
  position: "relative",
  height: "112px",
  minWidth: "270px",
  paddingBottom: "51.37%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  color: "#fff",
});

const AvatarWrapper = styled(Box)({
  position: "absolute",
  top: "12.5%",
  left: "50%",
  transform: "translateX(-50%)",
  width: "22.93%",
  borderRadius: "50%",
  overflow: "hidden",
  border: "4px solid white",
});

const Content = styled(Box)({
  padding: "14px",
});

const NameTypography = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 500,
  marginTop: "8px",
  position: "absolute",
  bottom: "12.5%",
});

const InfoTypography = styled(Typography)({
  color: "#555",
});

interface ContactModalProps {
  contact: {
    name?: string;
    email?: string;
    phoneNumber?: string;
    avatar_url: string;
  };
}

const ContactCard: React.FC<ContactModalProps> = ({
  contact: { name, email, phoneNumber, avatar_url },
}) => {
  return (
    <CardContainer>
      <Header>
        <AvatarWrapper>
          <Avatar
            alt={name}
            src={avatar_url || noPhoto}
            sx={{ width: "100%", paddingBottom: "100%" }}
          />
        </AvatarWrapper>
        <NameTypography>{name}</NameTypography>
      </Header>
      <Content>
        <InfoTypography>{email}</InfoTypography>
        <InfoTypography>{phoneNumber}</InfoTypography>
      </Content>
    </CardContainer>
  );
};

export default ContactCard;
