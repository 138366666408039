import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  Avatar,
  styled,
  Modal,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ReactPlayer from "react-player";
import { Message, MessageModalProps } from "../Interface";
import dayjs from "dayjs";
import { getIframeMessage } from "utils";
import ContactCard from "./ContactModal";
import noPhoto from "assets/images/no-photo.png";

const MessageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column-reverse",
  height: "calc(100vh - 120px)",
  overflowY: "auto",
  padding: "10px",
  backgroundColor: "#fff9e6",
  width: "550px",
});

const MessageBubble = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  maxWidth: "80%",
  marginBottom: theme.spacing(1),
  wordBreak: "break-word",
}));

const InputContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderTop: "1px solid #ddd",
  backgroundColor: "#fff",
});

const ChatHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  backgroundColor: "#fff",
});

const ImageWrapper = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
}));

const VideoWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  minHeight: "100px",
  borderRadius: "12px",
  overflow: "hidden",
});

const AudioWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  minWidth: "220px",
  borderRadius: "12px",
  overflow: "hidden",
});

const Popup: React.FC<MessageModalProps> = ({
  open,
  onClose,
  messages,
  reportUser,
  reporter,
  loading,
}) => {
  const [message, setMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const reporterId = reporter?.id;

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (open && !loading) {
      scrollToBottom();
    }
  }, [open, messages, loading]);

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };

  const renderMessage = (msg: Message) => {
    const { additional_data, type } = msg;

    switch (type) {
      case "IMAGE":
        return (
          <ImageWrapper
            src={additional_data?.url}
            alt="No img"
            onClick={() => handleImageClick(additional_data?.url)}
          />
        );
      case "VIDEO":
        return (
          <VideoWrapper>
            <ReactPlayer
              url={additional_data?.url}
              width={"100%"}
              height={"auto"}
              controls={true}
            />
          </VideoWrapper>
        );

      case "AUDIO":
        return (
          <AudioWrapper>
            <ReactPlayer
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              url={additional_data?.url}
              width={"100%"}
              height={"50px"}
              controls={true}
            />
          </AudioWrapper>
        );

      case "CONTACT":
        return <ContactCard contact={additional_data?.contact} />;

      default:
        return <Typography sx={{ fontSize: "0.875rem" }}>{msg.message}</Typography>;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          width: "550px",
          margin: "auto",
          position: "relative",
        }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ChatHeader>
          <Avatar
            alt="avatar"
            src={reporter?.avatar_url || noPhoto}
            sx={{ width: 32, height: 32, padding: "2px" }} // Adjust the size of the avatar
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1 }}>
            {reporter?.full_name} {reporter?.email}
          </Typography>
        </ChatHeader>
        <MessageContainer>
          <div ref={messagesEndRef} />
          {!loading &&
            messages.map((msg, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: msg.author_id === reporterId ? "row-reverse" : "row",
                    alignItems: "flex-start",
                    marginBottom: 1,
                  }}
                >
                  <Avatar
                    alt="avatar"
                    src={
                      msg.author_id === reporterId
                        ? reporter?.avatar_url || noPhoto
                        : reportUser?.avatar_url || noPhoto
                    }
                    sx={{ width: 22, height: 22, margin: "4px", padding: "2px" }}
                  />
                  <MessageBubble
                    sx={{ backgroundColor: msg.author_id === reporterId ? "#e0ffe0" : "#fff" }}
                  >
                    {renderMessage(msg)}
                    {getIframeMessage(msg?.message)}
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        textAlign: msg.author_id === reporterId ? "right" : "left",
                        marginTop: "16px",
                      }}
                    >
                      {dayjs(msg.created_at).format("MMM DD, YYYY HH:mm")}
                    </Typography>
                  </MessageBubble>
                </Box>
              );
            })}
        </MessageContainer>
        {!loading && (
          <InputContainer>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="Type message"
              disabled={true}
            />
            <IconButton disabled={true}>
              <SendIcon />
            </IconButton>
          </InputContainer>
        )}

        <Modal
          open={Boolean(selectedImage)}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              padding: 2,
              borderRadius: 1,
              width: "600px",
              height: "800px",
            }}
          >
            <img src={selectedImage} alt="Enlarged" />
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default Popup;
