/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import form from "./from";

const {
  formField: { fullName, email, password },
} = form;

const validation = Yup.object().shape({
  [fullName.name]: Yup.string().required(fullName.errorMsg),
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  [password.name]: Yup.string().when('isEdit', {
    is: true,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required(password.errorMsg).min(6, password.invalidMsg),
  })
});

export default validation;
