import { useMemo, useEffect, useCallback } from "react";
import { useTable, usePagination, useSortBy, ColumnWithLooseAccessor } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import * as XLSX from "xlsx";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Profiler Connextion examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  GetUsersQueryVariables,
  useGetOnboardingLinkLazyQuery,
  useGetUsersLazyQuery,
  useGetUsersToExportLazyQuery,
  useBlockUserMutation,
  useSoftDeleteUserMutation,
} from "services/api";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import { useDialog } from "hooks/useDialog";
import dayjs from "dayjs";
import { Tooltip, Switch, IconButton } from "@mui/material";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SystemUpdateAltTwoToneIcon from "@mui/icons-material/SystemUpdateAltTwoTone";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const navigate = useNavigate();
  const dialog = useDialog();

  const [blockUser] = useBlockUserMutation();
  const [softDeleteUser] = useSoftDeleteUserMutation();
  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);

  const baseQuery: GetUsersQueryVariables = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
      where: {
        role: {
          _eq: "COACH",
        },
      },
      order_by: { created_at: "desc" },
    }),
    []
  );

  const [query, { data: userResult }] = useGetUsersLazyQuery({
    variables: {
      ...baseQuery,
    },
  });

  const [queryUsersToExport] = useGetUsersToExportLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [getOnboardingLink, { loading: onboardingLoading }] = useGetOnboardingLinkLazyQuery({
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    if (!userResult?.users_aggregate) return 0;

    return userResult.users_aggregate.aggregate.count;
  }, [userResult?.users_aggregate]);

  const openOnboardLink = (link: string) => {
    dialog.open({
      title: "Stripe account onboarding link",
      body: <div>{link}</div>,
      actions: [
        {
          label: "Fermer",
          onClick: () => dialog.close(),
          type: "CANCEL",
        },
      ],
    });
  };

  const columns: ColumnWithLooseAccessor[] = useMemo(
    () => [
      { Header: "Nom complet", accessor: "full_name" }, // Full name
      { Header: "E-mail", accessor: "email" }, // Email
      {
        Header: "Créé le", // Created at
        accessor: "created_at",
        Cell: (e) => <span>{dayjs(e.value).toString()}</span>,
      },
      {
        Header: "Fermé à clé",
        accessor: "is_blocked",
        Cell: (e: any) => {
          const { original } = e.cell.row;
          return (
            <Tooltip title="L'utilisateur ne peut pas se connecter lorsqu'il est bloqué">
              <Switch
                checked={original.is_blocked}
                onChange={async () => {
                  if (original.is_blocked) {
                    const isConfirmed = window.confirm("Etes-vous sûr de vouloir débloquer ?");
                    if (isConfirmed) {
                      await blockUser({
                        variables: {
                          user_id: original?.id,
                          action: "UNBLOCK",
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  } else {
                    const isConfirmed = window.confirm("Etes-vous sûr de vouloir bloquer ?");
                    if (isConfirmed) {
                      await blockUser({
                        variables: {
                          user_id: original?.id,
                        },
                      });
                      fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  }
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        Header: "Compte Stripe", // Stripe Account
        accessor: "is_stripe_account_ready",
        align: "center",
        Cell: (e) => {
          if (e.value) {
            return (
              <MDBadge
                variant="contained"
                size="xs"
                badgeContent={"Vérifié"} // Verified
                container
                color="success"
                fontWeight="medium"
              />
            );
          }

          return (
            <MDButton
              variant="gradient"
              size="small"
              color="info"
              disabled={onboardingLoading}
              onClick={() =>
                getOnboardingLink({
                  variables: {
                    input: {
                      user_id: e.cell.row.values?.id,
                    },
                  },
                }).then((d) => openOnboardLink(d.data.getStripeAccountLink.link))
              }
            >
              Intégration
            </MDButton>
          );
        },
      },
      {
        Header: "Disponibilité", // Availability
        accessor: "id",
        Cell: (e) => (
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            onClick={() => navigate(`/dashboard/coaches/availability/${e.value}`)}
          >
            Disponibilité
          </MDButton>
        ),
      },
      {
        Header: "Actions",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const user_id = original.id;
          return (
            <>
              {!original.deleted_at ? (
                <IconButton
                  onClick={async () => {
                    const isConfirmed = window.confirm("Etes-vous sûr que vous voulez supprimer?");
                    if (isConfirmed) {
                      await softDeleteUser({
                        variables: {
                          user_id,
                        },
                      });
                      await fetchData({
                        limit: pageSize,
                        offset: pageIndex * pageSize,
                      });
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              ) : (
                <Tooltip title="L'utilisateur actuel est supprimé. Cliquez sur Si vous souhaitez restaurer">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir restaurer ?");
                      if (isConfirmed) {
                        await softDeleteUser({
                          variables: {
                            user_id,
                            action: "RECOVER",
                          },
                        });
                        await fetchData({
                          limit: pageSize,
                          offset: pageIndex * pageSize,
                        });
                      }
                    }}
                  >
                    <RestoreFromTrashOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton onClick={() => navigate(`/dashboard/coaches/${user_id}`)}>
                <EditOutlinedIcon />
              </IconButton>
            </>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => userResult?.users || [], [userResult]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;

    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
      },
      fetchPolicy: "no-cache",
    });
  }, []);

  useEffect(() => {
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  }, [pageIndex]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  const handleExportCoachs = async () => {
    const res = await queryUsersToExport({
      variables: {
        order_by: { created_at: "desc" },
        where: {
          role: {
            _eq: "COACH",
          },
        },
      },
    });
    const usersData = (res?.data?.users || []).map((user, index) => ({
      "#": index + 1,
      Id: user.id,
      Name: user?.full_name || "",
      Email: user?.email,
      Phone: user?.mobile || "",
      Role: user?.role || "",
      Compte_stripe: user?.is_stripe_account_ready ? "Yes" : "No",
      Blocked: user?.is_blocked ? "Yes" : "No",
      Deleted: user?.deleted_at ? "Yes" : "No",
      Deleted_at: user?.deleted_at ? dayjs(user?.deleted_at).format("YYYY-MMM-DD HH:mm:ss") : "",
      Created_at: dayjs(user?.created_at).format("YYYY-MMM-DD HH:mm:ss"),
    }));
    // Create a new workbook with a sheet
    const ws = XLSX.utils.json_to_sheet(usersData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    //  XLSX.writeFile(wb, 'users.xlsx'); // remove this line and lines code covert workbook to CSV format if you want to export without downloading file

    // Start convert the workbook to CSV format
    const csvData = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob and download the CSV file
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "coachs.csv";
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    // End convert the workbook to CSV format
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries.map((e) => e.toString())}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
                fetchData({
                  limit: parseInt(newValue, 10),
                  offset: 0,
                });
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Entrées par page
            </MDTypography>
          </MDBox>
        )}
        <Tooltip
          title="Exportez les utilisateurs au format CSV. Le système exportera tous les utilisateurs. "
          arrow
        >
          <IconButton
            sx={{
              "&:hover": {},
            }}
            color="inherit"
            size="medium"
            onClick={handleExportCoachs}
          >
            <SystemUpdateAltTwoToneIcon fontSize="medium" />
            <span style={{ margin: "2px", padding: "2px", fontSize: 13 }}>
              Exporter les utilisateurs
            </span>
          </IconButton>
        </Tooltip>
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Page {pageIndex + 1} sur {pageCount}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
