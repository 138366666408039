const form = {
  formId: "coach",
  formField: {
    fullName: {
      name: "fullName",
      label: "Nom complet", // Full Name
      type: "text",
      errorMsg: "Le nom complet est requis.", // Full name is required.
    },
    age: {
      name: "age",
      label: "Âge", // Age
      type: "number",
      errorMsg: "L'âge est requis.", // Age is required.
    },
    hourlyRate: {
      name: "hourly_rate",
      label: "Taux horaire", // Hourly rate
      type: "number",
      errorMsg: "Le taux horaire est requis.", // Hourly rate is required.
    },
    gender: {
      name: "gender",
      label: "Genre", // Gender
      type: "select",
      errorMsg: "Le genre est requis.", // Gender is required.
    },
    birthday: {
      name: "birthday",
      label: "Date de naissance", // Date of Birth
      type: "date",
      errorMsg: "La date de naissance est requise.", // Date of Birth is required.
    },
    email: {
      name: "email",
      label: "Adresse e-mail", // Email Address
      type: "email",
      errorMsg: "L'adresse e-mail est requise.", // Email address is required.
      invalidMsg: "Votre adresse e-mail est invalide", // Your email address is invalid
    },
    password: {
      name: "password",
      label: "Mot de passe", // Password
      type: "password",
      errorMsg: "Le mot de passe est requis.", // Password is required.
      invalidMsg: "Votre mot de passe doit contenir plus de 6 caractères.", // Your password should be more than 6 characters.
    },
    skills: {
      name: "skills",
      label: "Compétences", // Skills
      type: "select",
      errorMsg: "Les compétences sont requises.", // Skills are required.
    },
    isEdit: {
      name: "isEdit",
      label: "isEdit",
      type: "boolean",
    },
  },
};

export default form;
