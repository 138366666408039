// index.tsx

import { useMemo, useEffect, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Button } from "@mui/material";
import MessagePopup from "./MessagePopup";
import {
  useBlockUserByIdMutation,
  useUnblockUserByIdMutation,
  useGetMessagesByReportByIdLazyQuery,
  useGetUserReportsLazyQuery,
} from "services/api";
import dayjs from "dayjs";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [reporter, setReporter] = useState(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [fetchMessages] = useGetMessagesByReportByIdLazyQuery();

  const handleFetchMessages = async (id: number) => {
    const result = await fetchMessages({ variables: { id, limit: 9999 } });
    const { loading } = result;
    setMessages(result?.data?.user_reports_by_pk?.channel?.messages || []);
    setLoading(loading);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [blockUser] = useBlockUserByIdMutation();
  const [unlockUser] = useUnblockUserByIdMutation();
  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);
  const baseQuery = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
      where: {},
    }),
    []
  );

  const [query, { data: responseData }] = useGetUserReportsLazyQuery({
    variables: {
      ...baseQuery,
      order_by: {
        created_at: "desc",
      },
    },
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    return responseData?.user_reports_aggregate?.aggregate?.count || 0;
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return <span>{original?.id}</span>;
        },
      },
      {
        Header: "User",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return <span>{original?.user?.email}</span>;
        },
      },
      { Header: "Content", accessor: "content" }, // Full name
      {
        Header: "Créé le",
        accessor: "created_at",
        Cell: (e: any) => <span>{dayjs(e.value)?.format("lll")}</span>,
      },
      {
        Header: "Reported By",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return <span>{original?.reporter?.email}</span>;
        },
      },
      {
        Header: "Actions",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return (
            <div>
              {original?.user?.is_blocked ? (
                <Button
                  onClick={async () => {
                    unlockUser({
                      variables: {
                        user_id: original?.user?.id,
                      },
                    });
                    fetchData({
                      limit: pageSize,
                      offset: pageIndex * pageSize,
                    });
                  }}
                >
                  Débloquer
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    await blockUser({
                      variables: {
                        user_id: original?.user?.id,
                      },
                    });
                    fetchData({
                      limit: pageSize,
                      offset: pageIndex * pageSize,
                    });
                  }}
                >
                  Bloc
                </Button>
              )}

              <Button
                onClick={async () => {
                  setReporter(original.reporter);
                  handleFetchMessages(original.id);
                  setIsOpen(true);
                }}
                disabled={isLoading}
              >
                Conversation
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo<any>(() => responseData?.user_reports || [], [responseData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;

    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
      },
    });
  }, []);

  useEffect(() => {
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  }, [pageIndex]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries.map((e) => e.toString())}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
                fetchData({
                  limit: parseInt(newValue, 10),
                  offset: 0,
                });
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Entrées par page
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        <MessagePopup
          open={isOpen}
          onClose={handleCloseModal}
          messages={messages}
          reporter={reporter}
          loading={isLoading}
        />
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Page {pageIndex + 1} sur {pageCount}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
