/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUserOld from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Notifications from "layouts/pages/notifications";
import DataTables from "layouts/applications/data-tables";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Profiler Connextion components
import MDAvatar from "components/MDAvatar";
// @mui icons
import Icon from "@mui/material/Icon";

// Images
import UserList from "modules/users/UserList";
import UserFeeback from "modules/users/UserFeeback";
import UserReport from "modules/users/UserReport";
import CoachList from "modules/coaches/CoachList";
import { NewCoach } from "modules/coaches";
import TemplateQuestions from "modules/questionnaires/TemplateQuestions";
import MatricQuestions from "modules/questionnaires/MatricQuestions";
import AttachmentTest from "modules/questionnaires/AttachmentTest";
import AvatarTest from "modules/questionnaires/AvatarTest";
import AvailabilityTimeSlot from "modules/coaches/AvailabilityTimeSlot";
import BookingList from "modules/bookings/BookingList";
import MatchSettings from "layouts/pages/match-settings";

const routes = [
  {
    name: "Illustration",
    key: "illustration",
    route: "/login",
    component: <SignInBasic />,
  },
  {
    type: "collapse",
    name: "Tableau de bord",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    isPrivate: true,
    collapse: [
      {
        name: "Analytique",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
        isPrivate: true,
      },
    ],
  },
  { type: "title", title: "GESTION", key: "title-pages" },
  {
    type: "collapse",
    name: "Utilisatrices",
    key: "pages",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Liste des utilisateurs",
        key: "users",
        route: "/dashboard/users",
        isPrivate: true,
        component: <UserList />,
      },
      {
        name: "Liste des entraîneurs",
        key: "coaches",
        route: "/dashboard/coaches",
        isPrivate: true,
        component: <CoachList />,
      },
      {
        name: "Retour client",
        key: "feedback",
        route: "/dashboard/feedback",
        isPrivate: true,
        component: <UserFeeback />,
      },
      {
        name: "Rapports des utilisateurs",
        key: "report",
        route: "/dashboard/report",
        isPrivate: true,
        component: <UserReport />,
      },
      {
        name: "edit coach",
        key: "edit-coach",
        route: "/dashboard/coaches/:id",
        isPrivate: true,
        component: <NewCoach />,
        type: "hidden",
      },
      {
        name: "Nouvel entraîneur",
        key: "new-coach",
        route: "/dashboard/coaches/new-coach",
        isPrivate: true,
        component: <NewCoach />,
        type: "hidden",
      },
      {
        type: "hidden",
        name: "Disponibilité",
        key: "availability",
        route: "/dashboard/coaches/availability/:coach_id",
        isPrivate: true,
        component: <AvailabilityTimeSlot />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Réservation",
    key: "bookings",
    icon: <Icon fontSize="medium">event</Icon>,
    collapse: [
      {
        name: "Liste de réservation",
        key: "bookings",
        route: "/dashboard/bookings",
        isPrivate: true,
        component: <BookingList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Questionnaires",
    key: "questionnaires",
    icon: <Icon fontSize="medium">list</Icon>,
    collapse: [
      {
        name: "Modèle de questions",
        key: "questionnaires",
        route: "/dashboard/template-questions",
        isPrivate: true,
        component: <TemplateQuestions />,
      },
      {
        name: "Matricielles",
        key: "matricQuestions",
        route: "/dashboard/matric-questions",
        isPrivate: true,
        component: <MatricQuestions />,
      },
      {
        name: "Tests d'attachement",
        key: "attachmenttest",
        route: "/dashboard/attachment-test",
        isPrivate: true,
        component: <AttachmentTest />,
      },
      {
        name: "Tests d'avatars",
        key: "avatartests",
        route: "/dashboard/avatar-tests",
        isPrivate: true,
        component: <AvatarTest />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Paramètres",
    key: "settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Faire des allumettes",
        key: "matching",
        route: "/dashboard/matching-settings",
        isPrivate: true,
        component: <MatchSettings />,
      },
    ],
  },
];

export default routes;
